import * as React from 'react';
import { useRef } from 'react';
import axios from 'axios';
import { DefaultButton, PrimaryButton } from 'office-ui-fabric-react/lib/Button';
import { TextField } from 'office-ui-fabric-react/lib/TextField';
import { IStackTokens, Stack, IStackProps  } from 'office-ui-fabric-react/lib/Stack';
import { ActionButton, IconButton, IIconProps, IPersonaProps, MessageBar, MessageBarType } from 'office-ui-fabric-react';
import { Panel, PanelType } from 'office-ui-fabric-react/lib/Panel';
import { Dropdown, DropdownMenuItemType, IDropdownStyles, IDropdownOption } from 'office-ui-fabric-react/lib/Dropdown';
import { Icon } from 'office-ui-fabric-react/lib/Icon';
import { Toggle } from 'office-ui-fabric-react/lib/Toggle';
import { SearchBox } from 'office-ui-fabric-react/lib/SearchBox';
import { TEOPeoplePicker } from "./TEOPeoplePicker";
import { IRule, ITeoPersonaProps, ITeoSubscriberFolders, IRuleCondition, IRuleAction, ITeoSubscriberCategories, ITeoDictionary } from "./globals";

const dropdownStyles: Partial<IDropdownStyles> = {
    dropdown: { width: 300 }
};

const optionConditions: IDropdownOption[] = [
    { key: 'peopleDDHeader', text: 'People', itemType: DropdownMenuItemType.Header },
    { key: 'peopleFrom', text: 'From' },
    { key: 'peopleTo', text: 'To' },

    { key: 'meDDHeader', text: 'I am', itemType: DropdownMenuItemType.Header },
    { key: 'meTo', text: 'I am on the To line' },
    { key: 'meCc', text: 'I am on the Cc line' },
    { key: 'meToCc', text: 'I am on the To or Cc line' },
    { key: 'meNotTo', text: 'I am not on the To line' },
    { key: 'meOnly', text: 'I am the only recipient' },

    { key: 'containsDDHeader', text: 'Contains keyword', itemType: DropdownMenuItemType.Header },
    { key: 'containsSubject', text: 'Subject contains' },
    { key: 'containsBody', text: 'Message body contains' },
    { key: 'containsSubjectBody', text: 'Subject or body contains' },
    { key: 'containsPeopleSender', text: 'Sender address contains' },
    { key: 'containsPeopleRecipient', text: 'Recipient address contains' },
    { key: 'containsHeader', text: 'Message header contains' },

    { key: 'markedDDHeader', text: 'Marked', itemType: DropdownMenuItemType.Header },
    { key: 'markedImportant', text: 'Importance' },
    { key: 'markedSensitive', text: 'Sensitive' },
    //{ key: 'markedClassification', text: 'Classification' },

    { key: 'includesDDHeader', text: 'Message includes', itemType: DropdownMenuItemType.Header },
    { key: 'includesFlag', text: 'Flag' },
    //{ key: 'includesType', text: 'Type' },
    { key: 'hasAttachment', text: 'Attachment' }

    //{ key: 'messageSizeHeader', text: 'Message size', itemType: DropdownMenuItemType.Header },
    //{ key: 'messageSizeAtLeast', text: 'At least' },
    //{ key: 'messageSizeAtMost', text: 'At most' },

    //{ key: 'receivedHeader', text: 'Received', itemType: DropdownMenuItemType.Header },
    //{ key: 'receivedBefore', text: 'Before' },
    //{ key: 'receivedAfter', text: 'After' },

    //{ key: 'allHeader', text: 'All', itemType: DropdownMenuItemType.Header },
    //{ key: 'allMessages', text: 'Apply to all messages' },
];

const optionImportance: IDropdownOption[] = [
    { key: 'importanceHigh', text: 'High' },
    { key: 'importanceNormal', text: 'Normal' },
    { key: 'importanceLow', text: 'Low' },
];

const optionSensitive: IDropdownOption[] = [
    { key: 'sensitiveCompanyConfidential', text: 'Company confidential' },
    { key: 'sensitiveNormal', text: 'Normal' },
    { key: 'sensitivePersonal', text: 'Personal' },
    { key: 'sensitivePrivate', text: 'Private' },
];


//Move to Shared Enum via controlled
const optionFlag: IDropdownOption[] = [
    { key: 'notFlagged', text: 'Not Flagged' },
    { key: 'flagged', text: 'Flagged' },
    { key: 'complete', text: 'Complete' },

    //These are not supported in graph yet
    //{ key: 'flagAny', text: 'Any' },
    //{ key: 'flagCall', text: 'Call' },
    //{ key: 'flagDoNotForward', text: 'Do not forward' },
    //{ key: 'flagForward', text: 'Forward' },
    //{ key: 'flagForYourInfo', text: 'For your information' },
    //{ key: 'flagNoResponseNecessary', text: 'No response necessary' },
    //{ key: 'flagMarkAsRead', text: 'Mark as read' },
    //{ key: 'flagReply', text: 'Reply' },
    //{ key: 'flagReplyToAll', text: 'Reply to all' },
    //{ key: 'flagReview', text: 'Review' },
];

//const optionActions: IDropdownOption[] = [
//    { key: 'organizeHeader', text: 'Organize', itemType: DropdownMenuItemType.Header },
//    { key: 'move', text: 'Move to' },
//    { key: 'copy', text: 'Copy to' },
//    { key: 'delete', text: 'Delete' },
//    { key: 'markHeader', text: 'Mark', itemType: DropdownMenuItemType.Header },
//    { key: 'markRead', text: 'Mark as read' },
//    { key: 'markJunk', text: 'Mark as junk' },
//    { key: 'markImportance', text: 'Mark with importance' },
//    { key: 'categorize', text: 'Categorize ' },
//    { key: 'routeHeader', text: 'Route', itemType: DropdownMenuItemType.Header },
//    { key: 'forwardTo', text: 'Forward to' },
//    //{ key: 'forwardAsAttachment', text: 'Forward as attachment' },
//    //{ key: 'redirect', text: 'Redirect to' }
//];

const optionFolders: IDropdownOption[] = [
    //{ key: 'archive', text: 'Archive', data: { icon: 'Archive' } },
    //{ key: 'inbox', text: 'Inbox', data: { icon: 'Inbox' } },
    //{ key: 'delete', text: 'Deleted items', data: { icon: 'Delete' } },
    //{ key: 'junk', text: 'Junk email', data: { icon: 'Blocked' } },
    { key: 'divider_1', text: '-', itemType: DropdownMenuItemType.Divider }
];

const stackTokens: IStackTokens = {
    childrenGap: 10,
    padding: 10
};

const horizontalGapStackTokens: IStackTokens = {
    childrenGap: 10,
    padding: 5
};

const horizontalGapStackTokensPanel: IStackTokens = {
    childrenGap: 10,
    padding: 10
};

const addIcon: IIconProps = { iconName: 'Add' };
const cancelIcon: IIconProps = { iconName: 'Cancel' };
const filterIcon: IIconProps = { iconName: 'Filter' };

interface IErrorProps {
    resetChoice?: () => void;
}
const ActionsErrorMessage = (p: IErrorProps) => (
    <MessageBar
        messageBarType={MessageBarType.blocked}
        isMultiline={false}
        onDismiss={p.resetChoice}
        dismissButtonAriaLabel="Close"
        truncated={true}
        overflowButtonAriaLabel="See more"
    >
        <div><b>Action Error - There is an error with one of the actions you selected.</b></div>
        <div> You have selected an action that has already been selected.</div>
    </MessageBar>
);

const ConditionsErrorMessage = (p: IErrorProps) => (
    <MessageBar
        messageBarType={MessageBarType.blocked}
        isMultiline={false}
        onDismiss={p.resetChoice}
        dismissButtonAriaLabel="Close"
        truncated={true}
        overflowButtonAriaLabel="See more"
    >
        <div><b>Condition Error - There is an error with one of the conditions you selected.</b></div>
        <div> You have selected a condition that has already been selected.</div>
    </MessageBar>
);

export interface PanelProps {
    isPanelVisible: boolean;
    selectedRule: IRule,
    onChangeRule: (rule: IRule) => void,
    onSave: () => void,
    onCancel: () => void,
}

export interface PanelState {
    ruleConditions: IRuleCondition[];
    ruleActions: IRuleAction[];
    subscriberFolderList: IDropdownOption[];
    subscriberCategoriesList: IDropdownOption[];
    peopleList: IPersonaProps[];
    actionsDropdownList: IDropdownOption[];
    conditionsDropdownList: IDropdownOption[];
    saveButtonDisabled:boolean;
    actionValidationError:boolean;
    conditionValidationError: boolean;
}

export class TEOAddEditPanel extends React.Component<PanelProps, PanelState> {
    state = {
        subscriberFolderList: optionFolders,
        //This is the list of conditions on the panel
        ruleConditions: [{ type: "", value: "" }],
        //This is the list of actions on the panel
        ruleActions: [{ type: "", value:"" }],
        subscriberCategoriesList: [] as IDropdownOption[],
        peopleList: [] as ITeoPersonaProps[],
        saveButtonDisabled: true,
        actionsDropdownList: [] as IDropdownOption[],
        conditionsDropdownList: [] as IDropdownOption[],
        actionValidationError: false,
        conditionValidationError: false,
    };
    
    
    public render() {
        console.log('panel render fired');
        const {
            name = "",
            sequence = 0,
            monitoredFolders = [],
            description = "",
            conditions = [{ type: "", value: "" }],
            actions = [{ type: "", value:""}],
            enabled = false,
        } = { ...this.props.selectedRule };

        return (
            <div>
                <Panel
                    isOpen={this.props.isPanelVisible}
                    onDismiss={this.props.onCancel}
                    type={PanelType.custom}
                    customWidth="888px"
                    headerText="Rules"
                    onRenderFooterContent={this._onRenderFooterContent}>
                    <Stack tokens={stackTokens}>
                        <Stack horizontal disableShrink tokens={horizontalGapStackTokensPanel}>
                            <div className="numberCircle">1</div>
                            <TextField
                                placeholder="Name your rule"
                                name="ruleName"
                                defaultValue={name}
                                onChange={this.onNameChange}
                                onGetErrorMessage={this._getTextErrorMessage.bind(this,"name")}/>
                        </Stack>

                        <Stack horizontal disableShrink tokens={horizontalGapStackTokensPanel}>
                            <div className="numberCircle">2</div>
                            <TextField
                                placeholder="Add a description"
                                name="ruleDescription"
                                defaultValue={description}
                                onChange={this.onDescriptionChange}
                                onGetErrorMessage={this._getTextErrorMessage.bind(this, "description")} />
                        </Stack>

                        <Stack horizontal disableShrink tokens={horizontalGapStackTokensPanel}>
                            <div className="numberCircle">3</div>
                            <Dropdown
                                placeholder="Select folders to monitor"
                                defaultSelectedKeys={monitoredFolders.map(s => s.key)}
                                options={this.state.subscriberFolderList}
                                onChange={this.onMonitoredFolderChange}
                                multiSelect                                 
                                styles={dropdownStyles}/>
                        </Stack>

                        {this.conditionsList(conditions)}
                        <Stack hidden={!this.state.conditionValidationError}>
                            <div><ConditionsErrorMessage /></div>
                        </Stack>
                        <ActionButton iconProps={addIcon} onClick={this.addCondition}>Add another condition</ActionButton>

                        {this.actionsList(actions)}
                        <Stack hidden={!this.state.actionValidationError}>
                            <div><ActionsErrorMessage /></div>
                        </Stack>
                        <ActionButton iconProps={addIcon} onClick={this.addAction}>Add another action</ActionButton>

                        <Toggle label="Rule enabled" inlineLabel defaultChecked={enabled} onText="On" offText="Off" onChange={this.onEnableChange.bind(this)} />
                        
                    </Stack>

                </Panel>
            </div>
        );
    }
    

    //Generate the condition list
    //https://jsfiddle.net/mayankshukla5031/qL83cf2v/1/
    conditionsList(selectedConditions: IRuleCondition[]) {
        console.log('gen conditions list');
        console.log(selectedConditions);

        let filteredConditions = selectedConditions;//selectedConditions.filter(i => i.type !== null && i.type !== "");
        if (filteredConditions.length <= 0) return null;

        return filteredConditions.map((ruleCondition, i) => (
            <div key={i}>
                {this.conditionHeader(i)}
                <Stack horizontal disableShrink tokens={horizontalGapStackTokens}>
                    <div style={{ "width": "30px" }}>&nbsp;</div>
                    <Dropdown
                        id={'conditionDropdown-' + i}
                        placeholder={i === 0 ? "Select an option" : "And..."}
                        //options={optionConditions}
                        options={this.state.conditionsDropdownList}
                        selectedKey={ruleCondition.type || ""}
                        onChange={this.onConditionTypeDropdownChange.bind(this, i)}
                        styles={dropdownStyles}/>

                    {ruleCondition.type.toLowerCase().includes("toaddresses") ||
                        ruleCondition.type.toLowerCase().includes("fromaddresses")
                      ? <TEOPeoplePicker
                            peopleList={this.state.peopleList}
                            currentSelectedItems={this.getConditionsPeople(ruleCondition.value)}
                            onChange={this.onConditionValueChangePeoplePicker.bind(this, i)}
                        />
                        : null
                    }

                    {ruleCondition.type.toLowerCase().includes("includes") ||
                        ruleCondition.type.toLowerCase().includes("importance") ||
                        ruleCondition.type.toLowerCase().includes("sensitivity") ||
                        ruleCondition.type.toLowerCase().includes("flag")
                        ? <Dropdown
                            placeholder="Select an option"
                            defaultSelectedKey={this.getDictionaryKey(ruleCondition.value)}
                            options={this.getOptions(ruleCondition.type)}
                            onChange={this.onConditionValueChangeDropdown.bind(this, i)}
                            styles={dropdownStyles} />
                        : null
                    }

                    {(ruleCondition.type.toLowerCase().includes("subjectorbodycontains") ||
                        ruleCondition.type.toLowerCase().includes("fromcontains") ||
                        ruleCondition.type.toLowerCase().includes("tocontains") ||
                        ruleCondition.type.toLowerCase().includes("cccontains") ||
                        ruleCondition.type.toLowerCase().includes("toorcccontains")) ||
                        (ruleCondition.type.toLowerCase().includes("contains") &&
                        !ruleCondition.type.toLowerCase().includes("to") &&
                        !ruleCondition.type.toLowerCase().includes("from"))
                        ? <TextField
                            value={ruleCondition.value || ""}
                            onChange={this.onConditionValueChangeTextField.bind(this, i)}
                            onGetErrorMessage={this._getTextErrorMessage.bind(this, "value")} />
                        : null
                    }

                    {ruleCondition.type.toLowerCase().includes("olderthandays")
                        ? <TextField
                            value={ruleCondition.value || ""}
                            onChange={this.onConditionValueChangeTextField.bind(this, i)}
                            onGetErrorMessage={this._getNumberErrorMessage.bind(this, "number of days")} />
                        : null
                    }

                    {i > 0
                        ?
                        <IconButton iconProps={cancelIcon} onClick={this.removeCondition.bind(this, i)} />
                        : null
                    }

                </Stack>
            </div>
        ));
    }

    private conditionHeader = (i: number): JSX.Element => {
        if (i === 0) {
            return (
                <Stack horizontal disableShrink tokens={horizontalGapStackTokensPanel}>
                    <div className="numberCircle">4</div>
                    <label>Add a condition</label>
                </Stack>
            );
        } else {
            return (null);
        }
    };

    private getConditionsPeople = (jsonPeople: string): ITeoPersonaProps[] => {
        console.log('getPeople');
        if (!jsonPeople) return [];//empty string
        try {
            //let people: ITeoPersonaProps[] = JSON.parse(jsonPeople);
            
            //map back to people 
            let peopleDict: ITeoDictionary[] = JSON.parse(jsonPeople);
            let listPeopleProps: ITeoPersonaProps[]= peopleDict.map(i => ({ email: i.key, text: i.value}));
            
            return listPeopleProps;
        } catch (error) {
            console.log(error);
            return [];
        }
    };

    private getPeople = (jsonPeople: string): ITeoPersonaProps[] => {
        console.log('getPeople');
        if (! jsonPeople) return [];//empty string
        try {
            let people: ITeoPersonaProps[] = JSON.parse(jsonPeople);
            return people;
        } catch (error) {
            console.log(error);
            return [];
        }
    };

     private getDictionaryKey = (jsonDictionary: string): string => {
            console.log('getDictionaryKey');
            if (! jsonDictionary) return null;//empty string
            try {
                let dictionary: ITeoDictionary = JSON.parse(jsonDictionary);
                return dictionary.key;
            } catch (error) {
                console.log(error);
                return null;
            }
        };
 
    private getDictionaryKeys = (jsonDictionary: string): string[] => {
            console.log('getDictionaryIds');
            if (! jsonDictionary) return null;//empty string
            try {
                let dictionary: ITeoDictionary[] = JSON.parse(jsonDictionary);
                return dictionary.map(s=>s.key);
            } catch (error) {
                console.log(error);
                return null;
            }
        };

    //Generate the action list
    actionsList(selectedActions: IRuleAction[]) {
        console.log('gen actions list');

        let filteredActions = selectedActions; //selectedActions.filter(i => i.type !== null && i.type !== "");
        if (filteredActions.length <= 0) return null;

        //const actionList = this.state.actionsDropdownList;

        return selectedActions.map((ruleAction, i) => (
            <div key={i}>
                {this.actionHeader(i)}
                <Stack horizontal disableShrink tokens={horizontalGapStackTokens}>
                    <div style={{ "width": "30px" }}>&nbsp;</div>
                    <Dropdown
                        id={'actionDropdown-' + i}
                        placeholder={i === 0 ? "Select an option" : "And..."}
                        options={this.state.actionsDropdownList}
                        selectedKey={ruleAction.type || ""}
                        onChange={this.onActionTypeDropdownChange.bind(this, i)}
                        styles={dropdownStyles} />

                    {ruleAction.type.toLowerCase().indexOf("forward")!==-1
                        ? <TEOPeoplePicker
                            peopleList={this.state.peopleList}
                            currentSelectedItems={this.getPeople(ruleAction.value)}
                            onChange={this.onActionPeoplePickerChange.bind(this, i)}
                        />
                        : null
                    }

                    {ruleAction.type.toLowerCase().indexOf("copy")!==-1
                        ? <Dropdown
                            placeholder="Select an option"
                            multiSelect
                            defaultSelectedKeys={this.getDictionaryKeys(ruleAction.value)}
                            options={this.state.subscriberFolderList}
                            onRenderOption={this._onRenderFolderOption}
                            onChange={this.onActionValueMultiDropdownChange.bind(this, i)}
                            styles={dropdownStyles}
                        />
                        : null
                    }

                    {ruleAction.type.toLowerCase().indexOf("move")!==-1
                        ? <Dropdown
                            placeholder="Select an option"
                            defaultSelectedKey={this.getDictionaryKey(ruleAction.value)}
                            options={this.state.subscriberFolderList}
                            onRenderOption={this._onRenderFolderOption}
                            onChange={this.onActionValueSingleDropdownChange.bind(this, i)}
                            styles={dropdownStyles}
                        />
                        : null
                    }

                    {ruleAction.type.toLowerCase().indexOf("importance")!==-1
                        ? <Dropdown
                            placeholder="Select an option"
                            defaultSelectedKey={this.getDictionaryKey(ruleAction.value)}
                            options={this.getOptions("importance")}
                            onChange={this.onActionValueSingleDropdownChange.bind(this, i)}
                            styles={dropdownStyles}
                        />
                        : null
                    }

                    {ruleAction.type.toLowerCase().indexOf("category")!==-1
                        ? <Dropdown
                            placeholder="Select an option"
                            multiSelect
                            defaultSelectedKeys={this.getDictionaryKeys(ruleAction.value)}
                            options={this.state.subscriberCategoriesList}
                            onRenderOption={this._onRenderOption.bind(this, "TagSolid")}
                            onChange={this.onActionValueMultiDropdownChange.bind(this, i)}
                            styles={dropdownStyles}
                        />
                        : null
                    }

                {i > 0
                    ? <IconButton iconProps={cancelIcon} onClick={this.removeAction.bind(this, i)} />
                    : null
                }
                </Stack>
            </div>
        ));
    }
    
    //Actions
    private actionHeader = (i: number): JSX.Element => {
        if (i === 0) {
            return (
                <div>
                    <Stack horizontal disableShrink tokens={horizontalGapStackTokensPanel}>
                        <div className="numberCircle">5</div>
                        <label>Add an action</label>
                    </Stack>
                </div>
            );
        } else {
            return (null);
        }
    };

    //private _getNameErrorMessage = (value: string): string => {
    //    return value.length >= 1 ? '' : `Enter a name.`;
    //};

    private _getTextErrorMessage = (name: string, value: string): string => {
        return value.length >= 1 ? "" : `Enter a ${name}`;
    };

    private _getNumberErrorMessage = (name: string, value: string): string => {
        const re = /^[0-9\b]+$/;
        if (value === '' || !re.test(value)) {
            return `Enter ${name}`;
        }
    };

    
    private _onRenderFolderOption = (option: IDropdownOption): JSX.Element => {
        let iconName: string = "FolderHorizontal" ;
        if (option.data && option.data.icon) {
            iconName = option.data.icon;
        }

        return (
            <div>
                <Icon style={{ marginRight: '8px' }} iconName={iconName} aria-hidden="true" title={iconName} />
                <span>{option.text}</span>
            </div>
        );
    };

    private _onRenderOption = (iconName:string, option: ITeoSubscriberCategories): JSX.Element => {
        return (
            <div>
                <Icon style={{ marginRight: '8px', color: option.color }}  iconName={iconName} aria-hidden="true" title={iconName} />
                <span>{option.text}</span>
            </div>
        );
    };

    private _onRenderFooterContent = () => {
        return (
            <div>
                <PrimaryButton style={{ marginRight: '8px' }} onClick={this.handleSave}  disabled={this.state.saveButtonDisabled}>
                    Save
                </PrimaryButton>
                <DefaultButton onClick={this.handleDiscard}>Discard</DefaultButton>
            </div>
        );
    };

  
    private addCondition = () => {
        console.log('add condition');

        let updatedRule = this.props.selectedRule;
        updatedRule.conditions.push({ type: " ", value: " " });
        this.updateSelectedRule(updatedRule);
    }

    private removeCondition = (ruleConditionsIndex: number, ev: React.MouseEvent<HTMLElement>) => {
        console.log('remove Condition:' + ruleConditionsIndex);

        //Dont let them remove the last one.
        if (ruleConditionsIndex === 0) return;

        let updatedRule = this.props.selectedRule;
        updatedRule.conditions.splice(ruleConditionsIndex, 1);
        this.updateSelectedRule(updatedRule);

        //let ruleConditions = [...this.state.ruleConditions];
        //ruleConditions.splice(index, 1);
        //this.setState({ ruleConditions });
    };

    private onEnableChange(ev: React.MouseEvent<HTMLElement>, checked: boolean) {
        console.log('enable is ' + (checked ? 'checked' : 'not checked'));
        let updatedRule = this.props.selectedRule;
        updatedRule.enabled = checked;
        this.updateSelectedRule(updatedRule);
    }

    private onNameChange = (ev: React.FormEvent<HTMLInputElement>, newValue?: string) => {
        console.log('Name Changed:' + newValue);
        //this.setState({ name: newValue || "" });
        let updatedRule = this.props.selectedRule;
        updatedRule.name = newValue;
        this.updateSelectedRule(updatedRule);
    };

    private onDescriptionChange = (ev: React.FormEvent<HTMLInputElement>, newValue?: string) => {
        console.log('Description Changed:' + newValue);
        let updatedRule = this.props.selectedRule;
        updatedRule.description = newValue;
        this.updateSelectedRule(updatedRule);
    };

    private onMonitoredFolderChange = (
        ev: React.FormEvent<HTMLDivElement>,
        item: IDropdownOption) => {
        console.log("monitored folder change:" + item);

        let updatedRule = this.props.selectedRule;

        const newSelectedItems = [...updatedRule.monitoredFolders];
        if (item.selected) {
            // add the option if it's checked

            newSelectedItems.push({ "key": item.key as string, "value": item.text });
        } else {
            // remove the option if it's unchecked
            const currIndex = newSelectedItems.findIndex(x => x.key===item.key);
            if (currIndex > -1) {
                newSelectedItems.splice(currIndex, 1);
            }
        }
        
        updatedRule.monitoredFolders = newSelectedItems;
        this.updateSelectedRule(updatedRule);

    };
    
    private onConditionTypeDropdownChange = (ruleConditionsIndex: number,
        ev: React.FormEvent<HTMLDivElement>,
        option?: IDropdownOption,
        index?: number) => {
        console.log("condition dropdown change:" + option);

        if (null == option) return; //If no option selected
        let updatedRule = this.props.selectedRule;
        updatedRule.conditions[ruleConditionsIndex] = { type: option.key.toString(), value: "" };
        this.updateSelectedRule(updatedRule);
    };

    private onConditionValueChangePeoplePicker = (
        ruleConditionsIndex: number,
        peopleSelected: ITeoPersonaProps[]) => {
        console.log("PeoplePickerChanged");

        //var peopleEmailList = peopleSelected.map(obj => {
        //    var rObj: string[] = [];
        //    rObj.push(obj.email);
        //    return rObj;
        //});

        //let peopleEmailList = JSON.stringify(peopleSelected);

        //let peopleList: ITeoDictionary = { "key": peopleSelected.email, "value": peopleSelected.text};
        let peopleList: ITeoDictionary[] = peopleSelected.map(i =>({ key:i.email.toString(),value:i.text.toString()}));

        let updatedRule = this.props.selectedRule;
        //updatedRule.conditions[ruleConditionsIndex].value = peopleEmailList;
        updatedRule.conditions[ruleConditionsIndex].value = JSON.stringify(peopleList);;
        this.updateSelectedRule(updatedRule);
    }

    private onConditionValueChangeDropdown = (
        ruleConditionsIndex: number,
        ev: React.FormEvent<HTMLDivElement>,
        option?: IDropdownOption,
        index?: number) => {
        console.log("condition dropdown change:" + option);
        if (null == option) return; //If no option selected  let updatedRule = this.props.selectedRule;

        let updatedRule = this.props.selectedRule;

        let optionValue: ITeoDictionary = { "key": option.key.toString(), "value": option.text };

        updatedRule.conditions[ruleConditionsIndex].value = JSON.stringify(optionValue);
        this.updateSelectedRule(updatedRule);
    };

    private onConditionValueChangeTextField = (ruleConditionsIndex: number, ev: React.FormEvent<HTMLInputElement>, newValue?: string) => {
        console.log('on condition textField Change:' + newValue);

        let updatedRule = this.props.selectedRule;
        updatedRule.conditions[ruleConditionsIndex].value = newValue.toString();
        this.updateSelectedRule(updatedRule);

        //let ruleConditions = [...this.state.ruleConditions];
        //ruleConditions[index] = { ...ruleConditions[index], value: newValue };
        //this.setState({ ruleConditions });
    }

    //-----
   

    //private onActionTextFieldChange = (index: number, ev: React.FormEvent<HTMLInputElement>, newValue?: string) => {
    //    console.log('on action textField Change:' + newValue);
        
    //    let ruleActions = [...this.state.ruleActions];
    //    ruleActions[index] = { ...ruleActions[index], value: newValue };
    //    this.setState({ ruleActions });
    //}

   //Actions

    private onActionTypeDropdownChange = (ruleActionsIndex: number,
        ev: React.FormEvent<HTMLDivElement>,
        option?: IDropdownOption,
        index?: number) => {
        console.log("action dropdown change:" + option);

        if (null == option) return; //If no option selected
        
        let updatedRule = this.props.selectedRule;
        updatedRule.actions[ruleActionsIndex].type = option.key.toString();
        updatedRule.actions[ruleActionsIndex].value = "";
        this.updateSelectedRule(updatedRule);

        //let actionsDropdownIndex = this.state.actionsDropdownList.findIndex(x => x.key === option.key);
        //if (actionsDropdownIndex > -1) {
        //    let newList = this.state.actionsDropdownList;
        //    newList.splice(actionsDropdownIndex, 1);
        //    this.setState({ actionsDropdownList: newList });
        //}
       
    };

    private onActionValueSingleDropdownChange = (
        ruleActionsIndex: number,
        ev: React.FormEvent<HTMLDivElement>,
        option?: IDropdownOption) => {
        console.log("action dropdown change:" + option);

        if (null == option) return; //If no option selected
        let updatedRule = this.props.selectedRule;
        let optionValue: ITeoDictionary = {"key": option.key.toString(), "value":option.text};

        updatedRule.actions[ruleActionsIndex].value = JSON.stringify(optionValue);
        this.updateSelectedRule(updatedRule);
    };

    private onActionValueMultiDropdownChange = (
        ruleActionsIndex: number,
        ev: React.FormEvent<HTMLDivElement>,
        option?: IDropdownOption) => {
        console.log("action dropdown change:" + option);

        if (null == option) return; //If no option selected

        let updatedRule = this.props.selectedRule;
        let newSelectedItems = updatedRule.actions[ruleActionsIndex].value ? JSON.parse(updatedRule.actions[ruleActionsIndex].value): [];
        if (option.selected) {
            // add the option if it's checked
            let optionValue: ITeoDictionary = {"key": option.key.toString(), "value":option.text};
            newSelectedItems.push(optionValue);
            //newSelectedItems.push(option.key as string);
        } else {
            // remove the option if it's unchecked
            //const currIndex = newSelectedItems.indexOf(option.key);
            const currIndex = newSelectedItems.findIndex((x: { key: React.ReactText; }) => x.key === option.key);
            if (currIndex > -1) {
                newSelectedItems.splice(currIndex, 1);
            }
        }
     
        updatedRule.actions[ruleActionsIndex].value = JSON.stringify(newSelectedItems);
        this.updateSelectedRule(updatedRule);

    };
    
    private onActionPeoplePickerChange = (
        index: number,
        peopleSelected: ITeoPersonaProps[]) => {
        console.log("PeoplePickerChanged");

        let peopleEmailList = JSON.stringify(peopleSelected);

        let updatedRule = this.props.selectedRule;
        updatedRule.actions[index].value = peopleEmailList;
        this.updateSelectedRule(updatedRule);
    }

    private addAction = () => {
        let updatedRule = this.props.selectedRule;
        updatedRule.actions.push({ type: "", value: "" });
        this.updateSelectedRule(updatedRule);
        
        //this.setState(prevState => ({
        //    ruleActions: [...prevState.ruleActions, { type: "", value: "" }]
        //}));
    }

    private removeAction = (ruleActionsIndex: number, ev: React.MouseEvent<HTMLElement>) => {
        console.log('remove Action:' + ruleActionsIndex);
        if (ruleActionsIndex <= 0) return;

        let updatedRule = this.props.selectedRule;
        updatedRule.actions.splice(ruleActionsIndex, 1);
        this.updateSelectedRule(updatedRule);
        
        //let ruleActions = [...this.state.ruleActions];
        //ruleActions.splice(index, 1);
        //this.setState({ ruleActions });
    };


    // Get the correct dropdown list
    getOptions(conditionType: string): IDropdownOption[] {
        let returnVal: IDropdownOption[] = null;
        switch (conditionType.toLowerCase()) {
        case "markedimportant":
            returnVal = optionImportance;
            break;
        case "sensitivity":
            returnVal = optionSensitive;
            break;
        case "flagstatusmarked":
            returnVal = optionFlag;
            break;
        case "importance":
            returnVal = optionImportance;
            break;
        case "copy":
        case "move":
            returnVal = optionFolders;
            break;
        }
        return returnVal;
    }

    getActionList() {
        const uri = window.location.href + '/GetActions';
        console.log(uri);
        
        axios.get(uri,
            {
            transformResponse: [
                (data) => {
                    try {
                        let  resp = JSON.parse(data);
                        return resp;
                    } catch (error) {
                        throw Error(`[requestClient] Error parsing response JSON data - ${JSON.stringify(error)}`);
                    }
                }
            ]
            })
            .then((res) => {
                let data: IDropdownOption[] = res.data;
                for (var i = 0; i < data.length; i++) {
                    if (data[i].key.toString().toLowerCase().startsWith("header")) {
                        data[i].itemType = DropdownMenuItemType.Header;
                    };
                }

                this.setState({
                    actionsDropdownList: data
                });
            })
            .catch((error) => {
                if (error.response) {
                    console.log(error.response.data);
                }
                alert(error);
            });
    }

    getConditionList() {
        const uri = window.location.href + '/GetConditions';
        console.log(uri);

        axios.get(uri,
                {
                    transformResponse: [
                        (data) => {
                            try {
                                let resp = JSON.parse(data);
                                return resp;
                            } catch (error) {
                                throw Error(`[requestClient] Error parsing response JSON data - ${JSON.stringify(error)}`);
                            }
                        }
                    ]
                })
            .then((res) => {
                let data: IDropdownOption[] = res.data;
                for (var i = 0; i < data.length; i++) {
                    if (data[i].key.toString().toLowerCase().startsWith("header")) {
                        data[i].itemType = DropdownMenuItemType.Header;
                    };
                }

                this.setState({
                    conditionsDropdownList: data
                });
            })
            .catch((error) => {
                if (error.response) {
                    console.log(error.response.data);
                }
                alert(error);
            });
    }

    getPeopleList() {
        const uri = window.location.href + '/GetPeople';
        console.log(uri);
        axios.get(uri,
                {
                    transformResponse: [
                        (data) => {
                            try {
                                let resp: ITeoPersonaProps[] = JSON.parse(data);
                                return resp;
                            } catch (error) {
                                throw Error(`[requestClient] Error parsing response JSON data - ${JSON.stringify(error)}`);
                            }
                        }
                    ]
                })
            .then((res) => {
                this.setState({
                    peopleList: res.data
                    //mostRecentlyUsed: res.data,
                    //isLoaded: true,
                });
            })
            .catch((error) => {
                if (error.response) {
                    console.log(error.response.data);
                }
                alert(error);            
            });
    }

    getSubscriberFolders() {
        const uri = window.location.href + '/GetFolders';
        console.log(uri);
        axios.get(uri,
                {
                    transformResponse: [
                        (data) => {
                            try {
                                let resp: ITeoSubscriberFolders[] = JSON.parse(data);

                                return resp;
                            } catch (error) {
                                throw Error(`[requestClient] Error parsing response JSON data - ${JSON.stringify(error)}`);
                            }
                        }
                    ]
                })
            .then((res) => {
                //Add subscribers folders to dropdown
                let subscriberFolders: IDropdownOption[] = optionFolders;
                subscriberFolders = subscriberFolders.concat(res.data);
                this.setState({
                    subscriberFolderList: subscriberFolders,
                });
            })
            .catch((error) => {
                if (error.response) {
                    console.log(error.response.data);
                }
                alert(error);
            });
    }

    getSubscriberCategories() {
        const uri = window.location.href + '/GetCategories';
        console.log(uri);
        axios.get(uri,
                {
                    transformResponse: [
                        (data) => {
                            try {
                                let resp: ITeoSubscriberCategories[] = JSON.parse(data);
                                return resp;
                            } catch (error) {
                                throw Error(`[requestClient] Error parsing response JSON data - ${JSON.stringify(error)}`);
                            }
                        }
                    ]
                })
            .then((res) => {
                let subscriberCategories: IDropdownOption[] = res.data;
                this.setState({
                    subscriberCategoriesList: subscriberCategories,
                });
            })
            .catch((error) => {
                if (error.response) {
                    console.log(error.response.data);
                }
                alert(error);
            });
    }

    componentDidMount() {
        this.getSubscriberFolders();
        this.getSubscriberCategories();
        this.getPeopleList();
        this.getActionList();
        this.getConditionList();
        console.log('panel didmount');
    }
    
    private handleDiscard = (e: React.MouseEvent<HTMLElement>) => {
        e.preventDefault();
        e.stopPropagation();

        this.props.onCancel();
    }
    private handleSave = (e: React.MouseEvent<HTMLElement>) => {
        console.log('save rule');
        e.preventDefault();
        e.stopPropagation();
        //disable so cant double click
        this.setState({ saveButtonDisabled:true });
        
        let updatedRule: IRule = this.props.selectedRule;
        if (this.handleValidation(updatedRule,true)) {
            this.props.onSave();
        }else {
            //this.setState({ saveButtonDisabled: true });        
        }
    }

    private updateSelectedRule(updatedRule:IRule): boolean {
        //Always update
        this.props.onChangeRule(updatedRule);

        //validate form
        return this.handleValidation(updatedRule,false);
    }

    private handleValidation(updatedRule: IRule, btnClicked:boolean): boolean{
        console.log('validate rule');

        let formValid= true;
    
        //let updatedRule:IRule = this.props.selectedRule;
        if (updatedRule.name.length < 1) formValid = false;   
        if (updatedRule.description.length < 1) formValid = false;   
        if (updatedRule.monitoredFolders.length < 1) formValid = false; 
        if (updatedRule.conditions.length < 1) formValid = false; 
        if (updatedRule.actions.length < 1) formValid = false; 

        //Check all the conditions
        if (updatedRule.conditions.length >= 1) {
            for (var i = 0; i < updatedRule.conditions.length; i++) {

                //and was selected
                if (updatedRule.conditions[i].type.includes(" ")) {
                    formValid = false;
                    break;
                }

                if (updatedRule.conditions[i].value === "" || updatedRule.conditions[i].value === "[]") {
                    //There are some where a value is not required
                    if (!updatedRule.conditions[i].type.toLowerCase().includes("me") &&
                        !updatedRule.conditions[i].type.toLowerCase().includes("attachment") &&
                        !updatedRule.conditions[i].type.toLowerCase().includes("isread")){
                        formValid = false;
                        break;
                    }
                }
            }
        }

        //Check all the actions
        if (updatedRule.actions.length >= 1) {

            let actionsTypes = updatedRule.actions.map(i=>i.type);
            let actionDups = actionsTypes.filter((item, index) => actionsTypes.indexOf(item) != index);
            if (actionDups.length > 0) {
                formValid = false;
                this.setState({ actionValidationError: true });
            } else {
                this.setState({ actionValidationError: false });  
            }

            let conditionTypes = updatedRule.conditions.map(i => i.type);
            let conditionDups = conditionTypes.filter((item, index) => conditionTypes.indexOf(item) != index);
            if (conditionDups.length > 0) {
                formValid = false;
                this.setState({ conditionValidationError: true });
            } else {
                this.setState({ conditionValidationError: false });
            }

            if (formValid) { 
                for (var i = 0; i < updatedRule.actions.length; i++) {

                    //and was selected
                    if (updatedRule.actions[i].type.includes(" ")) {
                        formValid = false;
                        break;
                    }

                    if (updatedRule.actions[i].value === "" || updatedRule.actions[i].value === "[]") {

                        //There are some where a value is not required
                        if (!updatedRule.actions[i].type.toLowerCase().includes("delete") && 
                            !updatedRule.actions[i].type.toLowerCase().includes("read") && 
                            !updatedRule.actions[i].type.toLowerCase().includes("junk")) {
                            formValid = false;
                        }
                    }
                }
            }

        }

        if (!btnClicked)
            this.setState({ saveButtonDisabled: !formValid });        

        return formValid;
    }

}

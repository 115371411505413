import React, { useState } from 'react';
import axios from 'axios';
import { TEOGrid } from "./TEOGrid";
import { TEOAddEditPanel } from "./TEOAddEditPanel";
import { IRule } from "./globals";
import Globals = require("./globals");
import IRuleNew = Globals.IRuleNew;

type Props = {};
type State = {
    isPanelVisible: boolean,
    rules: IRule[],
    allRules: IRule[],
    selectedRule: IRule,
    error?: string,
    isLoaded: boolean,
    filter:string,
}

const defaultRule: IRule = {
    key: "",
    id: "",
    sequence: -1,
    name: "",
    monitoredFolders: [],
    conditions: [{ type: "", value: "" }],
    actions: [{ type: "", value: "" }],
    description: "",
    enabled: false,
}

const apiUrl = window.location.href;
export class TEOApp extends React.Component<Props, State> {
    
    state: State = {
        isPanelVisible: false,
        isLoaded: false,
        rules: [],
        allRules: [],
        filter:null,
        selectedRule: defaultRule,
    }
    
    showAddPanel = () => {
        console.log("Show Add Panel");

        const newRule: IRule = {
            key: "",
            id: "",
            sequence: -1,
            name: "",
            monitoredFolders: [],
            conditions: [{ type: "", value: "" }],
            actions: [{ type: "", value: "" }],
            description: "",
            enabled: false,
        };
        
        this.setState({
            isPanelVisible: true, 
            selectedRule: newRule
        });
    }
    
    showEditPanel = (id: string) => {
        console.log(id);
        var index = this.state.rules.findIndex(((p: any) => p.key === id) as any);
        if (index >= 0) {
            let selectedRule = this.state.rules[index];
            //get value not object reference
            selectedRule = JSON.parse(JSON.stringify(selectedRule));

            this.setState({ selectedRule: selectedRule });
            this.setState({ isPanelVisible: true });
        } else {
            //ToDo: Log Error
            alert("An unexpected error has occurred. Please notify support.");
        }
    }

    moveRuleUp = (ruleId: string) => {
        console.log('moveRuleUp' + ruleId);

        const apiUrl = window.location.href;
        const uri = apiUrl + '/moveUpRule';
        console.log(apiUrl);
        axios.post(uri,
                {
                    ruleId: ruleId
                })
            .then((response) => {
                console.log(response);
                this.getRulesData();
            })
            .catch(error => {
                if (error.response) {
                    console.log(error.response.data);
                }
                alert(error);
            });
    }

    moveRuleDown = (ruleId: string) => {
        console.log('moveRuleDown' + ruleId);

        const apiUrl = window.location.href;
        const uri = apiUrl + '/moveDownRule';
        console.log(apiUrl);
        axios.post(uri,
                {
                    ruleId: ruleId
                })
            .then((response) => {
                console.log(response);
                this.getRulesData();
            })
            .catch(error => {
                if (error.response) {
                    console.log(error.response.data);
                }
                alert(error);
            });

    }
    
    updateSelectedRule = (updatedRule: IRule) => {
        this.setState({ selectedRule: updatedRule });
    }

    onCancel = () => {
        console.log('OnCancel');
        this.setState({
            isPanelVisible: false
        });
    }

    hidePanel = () => {
        this.setState({ isPanelVisible: false });
    }

    onRuleDelete = (selectedItems: number[]) => {
        console.log('OnRuledelete' + selectedItems);
        this.setState((previousState: State) => {
            return {
                rules: previousState.rules.filter((item, itemIndex) => selectedItems.indexOf(itemIndex) < 0)
            };
        });
    }

    onSave = () => {
        console.log('saveSelected Rule');

        let selectedRule = this.state.selectedRule;
        
        let uri = apiUrl + '/AddRule';
        if (selectedRule.id !== "") {
            uri = apiUrl + '/UpdateRule';
        }
        console.log(uri);

        axios.post(uri,
            {
                rule:selectedRule               
            })
            .then((response) => {
                console.log(response);
                //reload grid
                this.setState({
                    isPanelVisible: false
                });
                this.getRulesData();
            })
            .catch(error => {
                if (error.response) {
                    console.log(error.response.data);
                }
                alert(error);
            });
    }
    
    enableToggle = (ev: React.MouseEvent<HTMLElement>, enabled: boolean) => {
        const ruleId = ev.currentTarget.id;
        console.log('enableToggle:' + (enabled ? 'enabled ' : 'not enabled ') + ruleId);

        const apiUrl = window.location.href;
        const uri = apiUrl + '/enableRule';
        console.log(apiUrl);
        axios.post(uri,
                {
                    ruleId: ruleId,
                    enabled:enabled
                })
            .then((response) => {
                console.log(response);
                this.getRulesData();
            })
            .catch(error => {
                if (error.response) {
                    console.log(error.response.data);
                }
                alert(error);
            });

    }

    sortColumn = (columnKey: string, isSortedDescending?: boolean) => {
        const items = this.state.rules;
        const key = columnKey as keyof IRule;
        const sortedItems =items.slice(0).sort((a: IRule, b: IRule) => ((isSortedDescending ? a[key] < b[key] : a[key] > b[key]) ? 1 : -1));

        this.setState({ rules: sortedItems });
    }
    
    componentDidMount() {
        this.getRulesData();//fetch data and set state
    }

    getRulesData = () => {
        console.log('GetRulesData');
        //https://github.com/axios/axios/blob/master/test/typescript/axios.ts
        //https://gist.github.com/klummy/cf739bdb3c63264d8d6427d9b6f8772d

        const uri = apiUrl + '/GetRules';
        console.log(apiUrl);
        axios.get(uri,
            {
                //transformResponse: [
                //    (data) => {
                //        try {
                //            let resp: IRuleNew[] = JSON.parse(data);
                //            return resp;
                //        } catch (error) {
                //            throw Error(`[requestClient] Error parsing response JSON data - ${JSON.stringify(error)}`);
                //        }
                //    }
                //]
            })
            .then((res) => {
                this.setState({
                    isLoaded: true,
                    rules: res.data,
                    allRules: res.data,
                });

                //filter grid
                if (null != this.state.filter) {
                    this.onFilter(this.state.filter);
                }

            })
            .catch(error => {
                if (error.response) {
                    console.log(error.response.data);
                }
                alert(error);
            });
    }

   onFilter = (text: string): void => {
       console.log('parent filter on change:' + text);
       const allRules = this.state.allRules;
       this.setState({
           rules: text ? allRules.filter(i => i.name.toLowerCase().indexOf(text) > -1) : allRules,
           filter: text
         });
    };


    render() {

        return (
            <div>
                <TEOGrid showAddPanel={this.showAddPanel} showEditPanel={this.showEditPanel} moveRuleUp={this.moveRuleUp} moveRuleDown={this.moveRuleDown} rules={this.state.rules} onDelete={this.onRuleDelete} isLoaded={this.state.isLoaded} sortColumn={this.sortColumn} enableToggle={this.enableToggle} onFilter={this.onFilter}/>
                <TEOAddEditPanel isPanelVisible={this.state.isPanelVisible} selectedRule={this.state.selectedRule} onChangeRule={this.updateSelectedRule} onSave={this.onSave} onCancel={this.onCancel}/>
            </div>
        );
    }
}
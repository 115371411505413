import React, { FunctionComponent, Component, useEffect, useState } from "react";
import axios from 'axios';
import { Link } from 'office-ui-fabric-react/lib/Link';
import { TextField } from 'office-ui-fabric-react/lib/TextField';
import { CommandBar } from 'office-ui-fabric-react/lib/CommandBar';
import { Toggle } from 'office-ui-fabric-react/lib/Toggle';
import { IContextualMenuProps, IContextualMenuItem, DirectionalHint, ContextualMenu } from 'office-ui-fabric-react/lib/ContextualMenu';
import {
    CheckboxVisibility,
    ColumnActionsMode,
    ConstrainMode,
    DetailsList,
    DetailsListLayoutMode,
    IColumn,
    IGroup,
    Selection,
    SelectionMode,
    buildColumns,
    IDetailsColumnProps
} from 'office-ui-fabric-react/lib/DetailsList';
import { ShimmeredDetailsList } from 'office-ui-fabric-react/lib/ShimmeredDetailsList';
import { Fabric } from 'office-ui-fabric-react/lib/Fabric';
import { IListProps } from 'office-ui-fabric-react/lib/index';
import { memoizeFunction } from 'office-ui-fabric-react/lib/Utilities';
import { getTheme, mergeStyles, mergeStyleSets } from 'office-ui-fabric-react/lib/Styling';
import { IconButton } from 'office-ui-fabric-react/lib/Button';
import { SearchBox } from 'office-ui-fabric-react/lib/SearchBox';
import { Stack, IStackTokens, IStackStyles } from 'office-ui-fabric-react';
import { initializeIcons } from 'office-ui-fabric-react/lib/Icons';
import { IRule, IRuleNew } from "./globals";
import Globals = require("./globals");


initializeIcons();

const wrapperClass = mergeStyles({
    padding: 2,
    selectors: {
        '& > .ms-Shimmer-container': {
            margin: '10px 0',
        },
    },
});

const theme = getTheme();
const classNames = mergeStyleSets({
    headerDivider: {
        display: 'inline-block',
        height: '100%'
    },
    headerDividerBar: {
        display: 'none',
        background: theme.palette.themePrimary,
        position: 'absolute',
        top: 16,
        bottom: 0,
        width: '1px',
        zIndex: 5
    },
    linkField: {
        display: 'block',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        maxWidth: '100%'
    },
    fileIconHeaderIcon: {
        padding: 0,
        fontSize: '16px'
    },
    fileIconCell: {
        textAlign: 'center',
        selectors: {
            '&:before': {
                content: '.',
                display: 'inline-block',
                verticalAlign: 'middle',
                height: '100%',
                width: '0px',
                visibility: 'hidden'
            }
        }
    },
    fileIconImg: {
        verticalAlign: 'middle',
        maxHeight: '16px',
        maxWidth: '16px'
    },
    controlWrapper: {
        display: 'flex',
        flexWrap: 'wrap'
    },
    exampleToggle: {
        display: 'inline-block',
        marginBottom: '10px',
        marginRight: '30px'
    },
    selectionDetails: {
        marginBottom: '20px'
    }
});
const rootClass = mergeStyles({
    selectors: {
        [`.${classNames.headerDivider}:hover + .${classNames.headerDividerBar}`]: {
            display: 'inline'
        }
    }
});
const controlStyles = {
    root: {
        margin: '0 30px 20px 0',
        maxWidth: '300px'
    }
};

const filterControlStyles = {
    root: {
        margin: '0 10px 10px 0',
        maxWidth: '300px',
        marginBottom: '40px',
        float: 'right'
    }
};

const stackTokens: IStackTokens = { childrenGap: 100 };
const stackStyles: IStackStyles = {
    root: {
        overflow: 'hidden',
        width: `100%`
    }
};

const stack1Styles: IStackStyles = {
    root: {
        overflow: 'hidden',
        width: `80%`
    }
};

const stack2Styles: IStackStyles = {
    root: {
        overflow: 'hidden',
        width: `20%`
    }
};

const DEFAULT_ITEM_LIMIT = 5;
const PAGING_SIZE = 10;
const PAGING_DELAY = 2000;
const ITEMS_COUNT = 15;

const shimmeredDetailsListProps: IListProps = {
    renderedWindowsAhead: 0,
    renderedWindowsBehind: 0,
};

const onRenderItemColumn = (item: IRule, index: number, column: IColumn): JSX.Element | string | number => {
    //console.log("ITEM KEY" + item.key);
    return item.key;
};


type State =  {
    canResizeColumns?: boolean;
    checkboxVisibility?: CheckboxVisibility;
    columns?: IColumn[];
    constrainMode?: ConstrainMode;
    contextualMenuProps?: IContextualMenuProps;
    groupItemLimit?: number;
    groups?: IGroup[];
    isHeaderVisible?: boolean;
    isLazyLoaded?: boolean;
    isSortedDescending?: boolean;
    items: IRule[];
    layoutMode?: DetailsListLayoutMode;
    selectionMode?: SelectionMode;
    sortedColumnKey?: string;
    selectionCount?: number;
}


type Props = {
    showAddPanel: () => void;
    showEditPanel: (id: string) => void;
    moveRuleUp: (id: string) => void;
    moveRuleDown: (id: string) => void;
    enableToggle: (ev: React.MouseEvent<HTMLElement>, checked: boolean) => void;
    sortColumn: (columnKey: string, isSortedDescending?: boolean) =>void;
    rules: IRule[];
    isLoaded: boolean;
    onDelete: (selectedItems: number[]) => void;
    onFilter: (text: string) => void;
}

export class TEOGrid extends React.Component<Props, State> {
    private _isFetchingItems: boolean;
    private _selection: Selection;
    private _allItems: IRule[];
    
    constructor(props:Props) {
        super(props);
        
        this._getCommandItems = memoizeFunction(this._getCommandItems);

        this._allItems = this.props.rules;
        this._selection = new Selection({
            onSelectionChanged: this._onItemsSelectionChanged
        });

        this._selection.setItems(this._allItems, false);    

        this.state = {
            items: this._allItems,
            selectionCount: 0,
            groups: undefined,
            groupItemLimit: DEFAULT_ITEM_LIMIT,
            layoutMode: DetailsListLayoutMode.justified,
            constrainMode: ConstrainMode.horizontalConstrained,
            selectionMode: SelectionMode.multiple,
            canResizeColumns: true,
            checkboxVisibility: CheckboxVisibility.onHover,
            contextualMenuProps: undefined,
            sortedColumnKey: 'name',
            isSortedDescending: false,
            isLazyLoaded: false,
            isHeaderVisible: true,
        };
    }
    
    public render(): JSX.Element {
        const {
            canResizeColumns,
            checkboxVisibility,
            constrainMode,
            contextualMenuProps,
            groupItemLimit,
            groups,
            isHeaderVisible,
            isLazyLoaded,
            //items,
            layoutMode,
            selectionMode
        } = this.state;

        //this._allItems = this.props.rules;

        const items = this.props.rules;
        const columns = this._buildColumns(this.props.rules,true,this._onColumnClick,'',undefined,undefined,this._onColumnContextMenu);

        const isGrouped = groups && groups.length > 0;
        const groupProps = {
            getGroupItemLimit: (group: IGroup) => {
                if (group) {
                    return group.isShowingAll ? group.count : Math.min(group.count, groupItemLimit as number);
                } else {
                    return items.length;
                }
            },
            footerProps: {
                showAllLinkText: 'Show all'
            }
        };

        return (
            <div className={rootClass}>
                
                <CommandBar
                    styles={{ root: { marginBottom: '30px' } }}
                    items={this._getCommandItems(
                        canResizeColumns,
                        checkboxVisibility,
                        constrainMode,
                        isHeaderVisible,
                        isLazyLoaded,
                        layoutMode,
                        selectionMode
                    )}
                    farItems={[{ key: 'count', text: `${this.state.selectionCount} selected` }]}
                />

                <SearchBox
                    styles={{ root: { width: 300 } }}
                    placeholder="Filter by rule name"
                    onFocus={() => console.log('onFocus called')}
                    onBlur={() => console.log('onBlur called')}
                    onChange={this._onChangeText}
                    onSearch={newValue => console.log('SearchBox onSearch fired: ' + newValue)}
                    iconProps={{ iconName: 'Filter' }}
                />
                {isGrouped ? <TextField label="Group item limit" onChange={this._onItemLimitChanged} /> : null}
                  
                <ShimmeredDetailsList
                    //getKey={this._getKey}
                   // onRenderItemColumn={onRenderItemColumn}
                  setKey="multiple"
                  items={items || []}
                  columns={columns}
                  enableShimmer={!this.props.isLoaded}
                  ariaLabelForShimmer="Content is being fetched"
                  ariaLabelForGrid="Item details"
                  listProps={shimmeredDetailsListProps}                  
                  selection={this._selection}
                  groups={groups}
                  checkboxVisibility={checkboxVisibility}
                  layoutMode={layoutMode}
                  isHeaderVisible={isHeaderVisible}
                  selectionMode={selectionMode}
                  constrainMode={constrainMode}
                  groupProps={groupProps}
                  enterModalSelectionOnTouch={true}
                  onItemInvoked={this._onItemInvoked}
                  onItemContextMenu={this._onItemContextMenu}
                  selectionZoneProps={{
                        selection: this._selection,
                        disableAutoSelectOnInputElements: true,
                        selectionMode: selectionMode
                    }}
                  ariaLabelForListHeader="Column headers. Click to sort."
                  ariaLabelForSelectAllCheckbox="Toggle selection for all items"
                  ariaLabelForSelectionColumn="Toggle selection"
                  checkButtonAriaLabel="Row checkbox"
                  onRenderMissingItem={this._onRenderMissingItem}
                />

                {contextualMenuProps && <ContextualMenu {...contextualMenuProps} />}
                
            </div>
            
        );
    }

    private _onChangeText = (ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, text: string): void => {
        console.log('filter chld on change:' + text);
        this.props.onFilter(text);
    };

    private _getKey(item: any, index?: number): string {
        return item.key;
    }

    private _onRenderDivider = (
        columnProps: IDetailsColumnProps,
        defaultRenderer: (props?: IDetailsColumnProps) => JSX.Element | null
    ): JSX.Element => {
        const { columnIndex } = columnProps;
        return (
            <React.Fragment key={`divider-wrapper-${columnIndex}`}>
                <span className={classNames.headerDivider}>{defaultRenderer(columnProps)}</span>
                <span className={classNames.headerDividerBar} />
            </React.Fragment>
        );
    };

    private _onDataMiss(index: number): void {
        index = Math.floor(index / PAGING_SIZE) * PAGING_SIZE;

        if (!this._isFetchingItems) {
            this._isFetchingItems = true;

            setTimeout(() => {
                this._isFetchingItems = false;
                const itemsCopy = [...this.state.items];

                itemsCopy.splice(index, PAGING_SIZE).concat(this._allItems.slice(index, index + PAGING_SIZE));

                this.setState({
                    items: itemsCopy
                });
            }, PAGING_DELAY);
        }
    }

    private _onRenderMissingItem = (index: number): null => {
        this._onDataMiss(index);
        return null;
    };

    
    private _onItemLimitChanged = (ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, value: string): void => {
        let newValue = parseInt(value, 10);
        if (isNaN(newValue)) {
            newValue = DEFAULT_ITEM_LIMIT;
        }
        this.setState({ groupItemLimit: newValue });
    };

    private _getCommandItems = (
        canResizeColumns?: boolean,
        checkboxVisibility?: CheckboxVisibility,
        constrainMode?: ConstrainMode,
        isHeaderVisible?: boolean,
        isLazyLoaded?: boolean,
        layoutMode?: DetailsListLayoutMode,
        selectionMode?: SelectionMode
    ): IContextualMenuItem[] => {
        return [
            {
                key: 'addRow',
                text: 'Add new rule',
                iconProps: { iconName: 'Add' },
                onClick: this._onAddRow,
            },
            {
                key: 'deleteRow',
                text: 'Delete Rule',
                iconProps: { iconName: 'Delete' },
                onClick: this._onDeleteRow
            }
        ];
    };

    private _getContextualMenuProps(ev: React.MouseEvent<HTMLElement>, column: IColumn): IContextualMenuProps {
        const items = [
            {
                key: 'aToZ',
                name: 'A to Z',
                iconProps: { iconName: 'SortUp' },
                canCheck: true,
                checked: column.isSorted && !column.isSortedDescending,
                onClick: () => this.props.sortColumn(column.key,false) //this._onSortColumn(column.key, false)
            },
            {
                key: 'zToA',
                name: 'Z to A',
                iconProps: { iconName: 'SortDown' },
                canCheck: true,
                checked: column.isSorted && column.isSortedDescending,
                onClick: () => this.props.sortColumn(column.key, true) //this._onSortColumn(column.key, true)
            }
        ];
        //if (isGroupable(column.key)) {
        //    items.push({
        //        key: 'groupBy',
        //        name: 'Group by ' + column.name,
        //        iconProps: { iconName: 'GroupedDescending' },
        //        canCheck: true,
        //        checked: column.isGrouped,
        //        onClick: () => this._onGroupByColumn(column)
        //    });
        //}
        return {
            items: items,
            target: ev.currentTarget as HTMLElement,
            directionalHint: DirectionalHint.bottomLeftEdge,
            gapSpace: 10,
            isBeakVisible: true,
            onDismiss: this._onContextualMenuDismissed
        };
    }
    //private _enableToggle = (ev: React.MouseEvent<HTMLElement>, checked: boolean):void => {
    //    console.log('toggle is ' + (checked ? 'checked' : 'not checked') + ev.currentTarget.id);
    //}
    private _onItemInvoked = (item: IRule, index: number): void => {
        console.log('Item invoked', item, index);
    };

    private _onItemContextMenu = (item: IRule, index: number, ev: MouseEvent): boolean => {
        const contextualMenuProps: IContextualMenuProps = {
            target: ev.target as HTMLElement,
            items: [
                {
                    key: 'text',
                    name: `${this._selection.getSelectedCount()} selected`
                }
            ],
            onDismiss: () => {
                this.setState({
                    contextualMenuProps: undefined
                });
            }
        };

        if (index > -1) {
            this.setState({
                contextualMenuProps: contextualMenuProps
            });
        }

        return false;
    };

    private _onColumnClick = (ev: React.MouseEvent<HTMLElement>, column: IColumn): void => {
        if (column.columnActionsMode !== ColumnActionsMode.disabled) {
            this.setState({
                contextualMenuProps: this._getContextualMenuProps(ev, column)
            });
        }
    };

    private _onColumnContextMenu = (column: IColumn, ev: React.MouseEvent<HTMLElement>): void => {
        if (column.columnActionsMode !== ColumnActionsMode.disabled) {
            this.setState({
                contextualMenuProps: this._getContextualMenuProps(ev, column)
            });
        }
    };

    private _onContextualMenuDismissed = (): void => {
        this.setState({
            contextualMenuProps: undefined
        });
    };

    private _onSortColumn = (columnKey: string, isSortedDescending: boolean): void => {
        const sortedItems = _copyAndSort(this._allItems, columnKey, isSortedDescending);
        console.log("sort" + this._allItems);

        this.setState({
            items: sortedItems,
            groups: undefined,
            columns: this._buildColumns(
                sortedItems,
                true,
                this._onColumnClick,
                columnKey,
                isSortedDescending,
                undefined,
                this._onColumnContextMenu
            ),
            isSortedDescending: isSortedDescending,
            sortedColumnKey: columnKey
        });
    };

    private _onGroupByColumn = (column: IColumn): void => {
        const { key, isGrouped } = column;
        const { sortedColumnKey, isSortedDescending, groups, items, columns } = this.state;

        if (isGrouped) {
            // ungroup
            this._onSortColumn(sortedColumnKey!, !!isSortedDescending);
        } else {
            let groupedItems = [];
            let newGroups: IGroup[];
            if (groups) {
                newGroups = [...groups];
                groupedItems = this._groupByKey(newGroups, items, key as keyof IRule);
            } else {
                groupedItems = _copyAndSort(items, key);
                newGroups = this._getGroups(groupedItems, key as keyof IRule);
            }

            for (const c of columns) {
                if (c.key === key) {
                    c.isGrouped = true;
                    break;
                }
            }
            this.setState({
                items: groupedItems,
                columns: [...columns],
                groups: newGroups
            });
        }
    };

    private _groupByKey(groups: IGroup[], items: IRule[], key: keyof IRule): IRule[] {
        let groupedItems: IRule[] = [];
        if (groups) {
            for (const group of groups) {
                if (group.children && group.children.length > 0) {
                    const childGroupedItems = this._groupByKey(group.children, items, key);
                    groupedItems = groupedItems.concat(childGroupedItems);
                } else {
                    const itemsInGroup = items.slice(group.startIndex, group.startIndex + group.count);
                    const nextLevelGroupedItems = _copyAndSort(itemsInGroup, key);
                    groupedItems = groupedItems.concat(nextLevelGroupedItems);
                    group.children = this._getGroups(nextLevelGroupedItems, key, group);
                }
            }
        }
        return groupedItems;
    }

    private _getGroups(groupedItems: IRule[], key: keyof IRule, parentGroup?: IGroup): IGroup[] {
        const separator = '-';
        const groups = groupedItems.reduce(
            (current: IGroup[], item: IRule, index: number) => {
                const currentGroup = current[current.length - 1];
                const itemColumnValue = item[key];

                if (!currentGroup || this._getLeafGroupKey(currentGroup.key, separator) !== itemColumnValue) {
                    current.push({
                        key: (parentGroup ? parentGroup.key + separator : '') + itemColumnValue,
                        name: key + ': ' + itemColumnValue,
                        startIndex: parentGroup ? parentGroup.startIndex + index : index,
                        count: 1,
                        level: parentGroup ? parentGroup.level! + 1 : 0
                    });
                } else {
                    currentGroup.count++;
                }
                return current;
            },
            [] as IGroup[]
        );

        return groups;
    }

    private _getLeafGroupKey(key: string, separator: string): string {
        let leafKey = key;
        if (key.indexOf(separator) !== -1) {
            const arrKeys = key.split(separator);
            leafKey = arrKeys[arrKeys.length - 1];
        }
        return leafKey;
    }

    private _onAddRow = (): void => {
        this.props.showAddPanel();
    };

    private _onDeleteRow = (): void => {
        let selectedCount = this._selection.getSelectedCount();
        if (selectedCount > 0) {
            let selectedItemIndices = this._selection.getSelectedIndices();
            console.log(selectedItemIndices);
            
            const selectedItems = this._selection.getSelection();
            const keyList = selectedItems.map(i => i.key);

            const apiUrl = window.location.href;
            const uri = apiUrl + '/DeleteRules';
            console.log(apiUrl);
            axios.post(uri,
                    {
                        ruleList: keyList
                    })
                .then((response) => {
                    console.log(response);
                    this.props.onDelete(selectedItemIndices);
                    this._selection.setAllSelected(false);
                })
                .catch(error => {
                    if (error.response) {
                        console.log(error.response.data);
                    }
                    alert(error);
                });

        }
        //let selectedCount: number = this.state.selectionCount;
        //if (selectedCount > 0) {
        //    this.setState((previousState: State) => {
        //        return {
        //            items: previousState.items.filter((item, index) => !this._selection.isIndexSelected(index))
        //        };
        //    });
        //} else {
        //    this.setState({
        //        items: this.state.items.slice(1)
        //    });
        //}
    };

    //ondelete = () => {
    //    console.log('saveSelected Rule');

    //    let selectedRule = this.state.selectedRule;

    //    const uri = apiUrl + '/SaveRule';
    //    console.log(apiUrl);
    //    axios.post(uri,
    //            {
    //                rule: selectedRule
    //            })
    //        .then((response) => {
    //            console.log(response);
    //        })
    //        .catch(error => {
    //             if (error.response) {
    //                  console.log(error.response.data);
                   //}
                //alert(error);
    //        });
    //}

    private _onItemsSelectionChanged = () => {
        this.setState({
            selectionCount: this._selection.getSelectedCount()
        });
    };

    private _buildColumns(
        items: IRule[],
        canResizeColumns?: boolean,
        onColumnClick?: (ev: React.MouseEvent<HTMLElement>, column: IColumn) => any,
        sortedColumnKey?: string,
        isSortedDescending?: boolean,
        groupedColumnKey?: string,
        onColumnContextMenu?: (column: IColumn, ev: React.MouseEvent<HTMLElement>) => any
    ) {

        const columns: IColumn[] = [
            {
                key: 'column1',
                name: '',
                fieldName: 'enabled',
                minWidth: 50,
                maxWidth: 50,
                isRowHeader: true,
                //isResizable: true,
                onColumnClick: this._onColumnClick,
                data: 'string',
                isPadded: true,
                onRender: (item: IRule) => {
                    return <Toggle checked={item.enabled} onChange={this.props.enableToggle} id={item.key}/>;
                },
            },
            {
                key: 'column2',
                name: 'Name',
                fieldName: 'name',
                minWidth: 100,
                maxWidth: 150,
                isRowHeader: true,
                isResizable: true,
                //isSorted: true,
                //isSortedDescending: false,
                sortAscendingAriaLabel: 'Sorted A to Z',
                sortDescendingAriaLabel: 'Sorted Z to A',
                onColumnClick: this._onColumnClick,
                data: 'string',
                isPadded: true
            },
            {
                key: 'column3',
                name: 'Monitored Folders',
                fieldName: 'monitoredFolders',
                minWidth: 100,
                maxWidth: 150,
                isRowHeader: true,
                isResizable: true,
                //isSorted: true,
                //isSortedDescending: false,
                sortAscendingAriaLabel: 'Sorted A to Z',
                sortDescendingAriaLabel: 'Sorted Z to A',
                onColumnClick: this._onColumnClick,
                data: 'string',
                onRender: (item: IRule) => {
                    //var monitoredFolders = item.monitoredFolders.join(", ");
                    var monitoredFolders = item.monitoredFolders.map(s=>s.value).join(", ");
                    return <span>{monitoredFolders}</span>;
                },
                isPadded: true
            },
            {
                key: 'column4',
                name: 'Description',
                fieldName: 'description',
                minWidth: 100,
                //maxWidth: 400,
                isResizable: true,
                isMultiline : true,
                onColumnClick: this._onColumnClick,
                data: 'string',
                //onRender: (item: IDocument) => {
                //    return <span>{item.dateModified}</span>;
                //},
                isPadded: true
            },
            {
                key: 'column5',
                name: '',
                fieldName: 'sequence',
                minWidth: 150,
                //maxWidth: 90,
                isResizable: true,
                isCollapsible: true,
                data: 'string',
                onColumnClick: this._onColumnClick,
                onRender: (item: IRule) => {
                    return <span>
                            <Stack horizontal>
                               <IconButton iconProps={{ iconName: 'Up' }} title="Move up" ariaLabel="Move Up" onClick={() => this.props.moveRuleUp(item.key)} />
                               <IconButton iconProps={{ iconName: 'Down' }} title="Move down" ariaLabel="Move Down" onClick={() => this.props.moveRuleDown(item.key)} />
                               <IconButton iconProps={{ iconName: 'Edit' }} title="Edit" ariaLabel="Edit" onClick={() => this.props.showEditPanel(item.key)} />
                            </Stack>
                        </span>;
                },
                isPadded: true
            }
        ];

        //Save for another iteration
        //<IconButton iconProps={{ iconName: 'Play' }} title="Run rule" ariaLabel="Run rule" onClick={() => this.props.showEditPanel(item.key)} />
        return columns;
    }

}

function _copyAndSort<T>(items: T[], columnKey: string, isSortedDescending?: boolean): T[] {
    const key = columnKey as keyof T;
    return items.slice(0).sort((a: T, b: T) => ((isSortedDescending ? a[key] < b[key] : a[key] > b[key]) ? 1 : -1));
}

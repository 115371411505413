import * as React from 'react';
import axios from 'axios';
import {
    IBasePickerSuggestionsProps,
    IBasePicker,
    NormalPeoplePicker,
    ValidationState
} from 'office-ui-fabric-react/lib/Pickers';
import { ITeoPersonaProps, ITeoPersonaProps as ITeoPersonaProps1 } from "./globals";

export interface IPeoplePickerState {
    currentPicker?: number;
    delayResults?: boolean;
    peopleList: ITeoPersonaProps[];
    filteredPeopleList: ITeoPersonaProps[];
    mostRecentlyUsed: ITeoPersonaProps[];
    currentSelectedItems?: ITeoPersonaProps[];
    isPickerDisabled?: boolean;
    isLoaded: boolean;
}

export interface IPeoplePickerProps  {
    onChange: (peopleSelected: ITeoPersonaProps[]) => void;
    currentSelectedItems: ITeoPersonaProps[];
    peopleList: ITeoPersonaProps[];
}

const suggestionProps: IBasePickerSuggestionsProps = {
    suggestionsHeaderText: 'Suggested People',
    mostRecentlyUsedHeaderText: 'Suggested Contacts',
    noResultsFoundText: 'No results found',
    loadingText: 'Loading',
    showRemoveButtons: true,
    suggestionsAvailableAlertText: 'People Picker Suggestions available',
    suggestionsContainerAriaLabel: 'Suggested contacts'
};

export class TEOPeoplePicker extends React.Component<IPeoplePickerProps, IPeoplePickerState > {
    // All pickers extend from BasePicker specifying the item type.
    private _picker = React.createRef<IBasePicker<ITeoPersonaProps>>();

        constructor(props: IPeoplePickerProps) {
            super(props);
            this.state = {
                currentPicker: 1,
                delayResults: false,
                mostRecentlyUsed: this.props.peopleList,
                currentSelectedItems: this.props.currentSelectedItems,
                peopleList: this.props.peopleList,
                filteredPeopleList: [],
                isPickerDisabled: false,
                isLoaded: false,
        };
    }
    
    public render() {

        return (
            <div style={{ width: 400 }}>
                <NormalPeoplePicker
                    onResolveSuggestions={this._onFilterChanged}
                    onEmptyInputFocus={this._returnMostRecentlyUsed}
                    getTextFromItem={this._getTextFromItem}
                    pickerSuggestionsProps={suggestionProps}    
                    className={'ms-PeoplePicker'}
                    key={'normal'}
                    selectedItems={this.state.currentSelectedItems}
                    onRemoveSuggestion={this._onRemoveSuggestion}
                    onValidateInput={this._validateInput}
                    removeButtonAriaLabel={'Remove'}
                    inputProps={{
                        onBlur: (ev: React.FocusEvent<HTMLInputElement>) => console.log('onblur called'),
                        onFocus: (ev: React.FocusEvent<HTMLInputElement>) => console.log('onFocus called'),
                        'aria-label': 'People Picker'
                    }}
                    componentRef={this._picker}
                    onInputChange={this._onInputChange}
                    resolveDelay={300}
                    disabled={this.state.isPickerDisabled}
                    onChange={this._onItemsChange}
                />
            </div>
        );
    }

    private _renderFooterText = (): JSX.Element => {
        return <div>No additional results</div>;
    };

    private _onItemsChange = (items: any[]): void => {
        console.log('picker on item change:');
        this.setState({
            currentSelectedItems: items
        });

        this.props.onChange(items);
    };

    private _getTextFromItem(persona: ITeoPersonaProps): string {
        return persona.text as string;
    }
   
    private _onRemoveSuggestion = (item: ITeoPersonaProps): void => {
        const { peopleList, mostRecentlyUsed: mruState } = this.state;
        const indexPeopleList: number = peopleList.indexOf(item);
        const indexMostRecentlyUsed: number = mruState.indexOf(item);

        if (indexPeopleList >= 0) {
            const newPeople: ITeoPersonaProps[] = peopleList.slice(0, indexPeopleList).concat(peopleList.slice(indexPeopleList + 1));
            this.setState({ peopleList: newPeople });
        }

        if (indexMostRecentlyUsed >= 0) {
            const newSuggestedPeople: ITeoPersonaProps[] = mruState
                .slice(0, indexMostRecentlyUsed)
                .concat(mruState.slice(indexMostRecentlyUsed + 1));
            this.setState({ mostRecentlyUsed: newSuggestedPeople });
        }
    };
    
    private _onFilterChanged = (
        filterText: string,
        currentPersonas: ITeoPersonaProps[],
        limitResults?: number
    ): ITeoPersonaProps[] | Promise<ITeoPersonaProps[]> => {
        console.log('onFilterChange');
        if (filterText) {
            console.log('filter list');

            return new Promise((resolve, reject) => {
                this.searchPeople(filterText.toLowerCase()).then((result: Array<ITeoPersonaProps>) => {
                    resolve(this._removeDuplicates(result, currentPersonas));
                });
            });
            
            //let filteredPersonas: ITeoPersonaProps[] = this._filterPersonasByText(filterText);
            //filteredPersonas = this._removeDuplicates(filteredPersonas, currentPersonas);
            //filteredPersonas = limitResults ? filteredPersonas.splice(0, limitResults) : filteredPersonas;

            //console.log('return filtered list');
            //return this._filterPromise(filteredPersonas);
            
        } else {
            return [];
        }
    };

    searchPeople(filterText: string): Promise<Array<ITeoPersonaProps>> {
        console.log('Search People:'+filterText);
        return new Promise((resolve, reject) => {
            const uri = window.location.href + '/GetPeople/?$search=' + filterText;
            axios.get(uri,
                    {
                        transformResponse: [
                            (data) => {
                                try {
                                    let resp: ITeoPersonaProps[] = JSON.parse(data);
                                    return resp;
                                } catch (error) {
                                    throw Error(
                                        `[requestClient] Error parsing response JSON data - ${JSON.stringify(error)}`);
                                }
                            }
                        ]
                    })
                .then((res) => {
                    console.log('search done' + res.data);
                    resolve(res.data);
                });
        });
    }
    
    private _returnMostRecentlyUsed = (currentPersonas: ITeoPersonaProps[]): ITeoPersonaProps[] | Promise<ITeoPersonaProps[]> => {
        let { mostRecentlyUsed } = this.state;
        mostRecentlyUsed = this._removeDuplicates(mostRecentlyUsed, currentPersonas);
        return this._filterPromise(mostRecentlyUsed);
    };
    
    private _filterPromise(personasToReturn: ITeoPersonaProps[]): ITeoPersonaProps[] | Promise<ITeoPersonaProps[]> {
        if (this.state.delayResults) {
            return this._convertResultsToPromise(personasToReturn);
        } else {
            return personasToReturn;
        }
    }

    private _listContainsPersona(persona: ITeoPersonaProps, personas: ITeoPersonaProps[]) {
        if (!personas || !personas.length || personas.length === 0) {
            return false;
        }
        return personas.filter(item => item.text === persona.text).length > 0;
    }

    private _filterPersonasByText(filterText: string): ITeoPersonaProps[] {
        console.log('search with filter:' + filterText);
        
        return this.state.peopleList.filter(item => this._doesTextStartWith(item.text as string, filterText));
    }

    private _doesTextStartWith(text: string, filterText: string): boolean {
        return text.toLowerCase().indexOf(filterText.toLowerCase()) === 0;
    }

    private _convertResultsToPromise(results: ITeoPersonaProps1[]): Promise<ITeoPersonaProps[]> {
        return new Promise<ITeoPersonaProps[]>((resolve, reject) => setTimeout(() => resolve(results), 3000));
    }

    private _removeDuplicates(personas: ITeoPersonaProps[], possibleDupes: ITeoPersonaProps[]) {
        return personas.filter(persona => !this._listContainsPersona(persona, possibleDupes));
    }
    
    private _validateInput = (input: string): ValidationState => {
        if (input.indexOf('@') !== -1) {
            return ValidationState.valid;
        } else if (input.length > 1) {
            return ValidationState.warning;
        } else {
            return ValidationState.invalid;
        }
    };

    /**
     * Takes in the picker input and modifies it in whichever way
     * the caller wants, i.e. parsing entries copied from Outlook (sample
     * input: "Aaron Reid <aaron>").
     *
     * @param input The text entered into the picker.
     */
    private _onInputChange(input: string): string {
        console.log('picker._onInputChange');

        const outlookRegEx = /<.*>/g;
        const emailAddress = outlookRegEx.exec(input);

        if (emailAddress && emailAddress[0]) {
            return emailAddress[0].substring(1, emailAddress[0].length - 1);
        }

        return input;
    }
    
}